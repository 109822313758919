import { Experiments, mapExperiments } from '../experiments/experiments';
import { isMobileFromFormFactor } from '../utils';
import {
  mergeMobileDefaultsIntoDesktop,
  mergeMobileSettingsIntoDesktop,
} from './mobileToDesktopKeys';

const hexToRGBA = (hex, opacity) => {
  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity / 100})`;
};

const normalizeOpacity = opacity => (opacity === 0 ? 0 : opacity || 100);
const isFont = data => data && !!data.editorKey;
const isColor = data => data && !!data.themeName;

function overrideSiteFontIfNeeded(font, siteTextPresets, isSettingsPanel) {
  if (isFont(font)) {
    const siteFont = Object.values(siteTextPresets).find(
      (textPreset: any) => textPreset.editorKey === font.editorKey,
    ) as any;

    const overloadedFont = {
      ...siteFont,
      ...font,
      family: siteFont.fontFamily,
    };

    overloadedFont.style = {
      bold: overloadedFont.weight === 'bold',
      italic: overloadedFont.style === 'italic',
    };

    const stringToTrim = 'font:';

    if (
      overloadedFont.value &&
      overloadedFont.value.indexOf(stringToTrim) === 0
    ) {
      overloadedFont.value = '';
    }

    return isSettingsPanel ? overloadedFont : JSON.stringify(overloadedFont);
  }
  return font;
}

function overrideSiteColorIfNeeded(color, siteColors) {
  if (isColor(color)) {
    const siteColor = siteColors.find(
      ({ reference }) => reference === color.themeName,
    );

    const overloadedColor = {
      reference: color.themeName,
      value: hexToRGBA(siteColor.value, normalizeOpacity(color.opacity)),
    };

    return overloadedColor;
  }
  return color;
}

export function overrideSiteDate(
  data,
  siteTextPresets,
  siteColors,
  isSettingsPanel,
) {
  if (isFont(data)) {
    return overrideSiteFontIfNeeded(data, siteTextPresets, isSettingsPanel);
  }

  if (isColor(data)) {
    return overrideSiteColorIfNeeded(data, siteColors);
  }

  return data;
}

export function overrideStyleProps(props) {
  const defaultOverrideData = {};
  const experimentsMap: Experiments = mapExperiments(props.experiments);
  const isMobile = isMobileFromFormFactor(props);
  const { siteTextPresets, siteColors } = props.style;
  let { settingsUserData, settingsDefaultData } = props;
  if (isMobile && experimentsMap.isListWidgetMobileSettingsEnabled) {
    settingsDefaultData = mergeMobileDefaultsIntoDesktop(settingsDefaultData);
    settingsUserData = mergeMobileSettingsIntoDesktop(settingsUserData);
  }

  // here we prepere the data with the designer/ux overrides
  for (const key in settingsDefaultData) {
    const defaultData = settingsDefaultData[key];

    defaultOverrideData[key] = overrideSiteDate(
      defaultData,
      siteTextPresets,
      siteColors,
      false,
    );
  }

  const styleParamsData = {
    ...props.style.styleParams.colors,
    ...props.style.styleParams.fonts,
  };

  const settingsData = {
    ...settingsDefaultData, // ALL THE DATA FROM THE SITE FLAT!!
    ...defaultOverrideData, // DESINGER OVERRIDE
    ...styleParamsData, // Data from Style params (ADI preset recorder)
    ...settingsUserData, //USER DATA
  };

  props.style.styleParams = {
    colors: settingsData,
    booleans: settingsData,
    fonts: settingsData,
    numbers: settingsData,
  };

  return {
    ...props,
    settingsData,
  };
}
