export interface Experiments {
  isWidgetPhaseOnePointFiveEnabled?: boolean;
  isWidgetAdditionalPaddingEnabled?: boolean;
  isListWidgetMobileSettingsEnabled?: boolean;
}

export const experimentsSpecMap = {
  isWidgetPhaseOnePointFiveEnabled: 'specs.bookings.WidgetPhase1_5',
  isWidgetAdditionalPaddingEnabled: 'specs.bookings.WidgetAdditionalPadding',
  isListWidgetMobileSettingsEnabled:
    'specs.bookings.ListWidgetMobileSettingsSpecs',
};

function isExperimentsTrue(experimentValue) {
  return experimentValue === 'true' || experimentValue === 'new';
}

export function mapExperiments(experiments: any): Experiments {
  const mappedExperiments: Experiments = {};

  Object.entries(experimentsSpecMap).forEach(entry => {
    const experimentEnablerName = entry[0];
    const experimentSpec = entry[1];
    mappedExperiments[experimentEnablerName] =
      experiments && experiments[experimentSpec]
        ? isExperimentsTrue(experiments[experimentSpec])
        : false;
  });

  return mappedExperiments;
}
