//define the mobile setting keys that should use the desktop if not defined

import { MobileSettingsKeys, SettingsKeys } from './SettingsKeys';

const desktopSettingsDefaultsToMobileMap = {
  [SettingsKeys.CARDS_SPACING]: MobileSettingsKeys.MOBILE_CARDS_SPACING,
  [SettingsKeys.TEXT_ALIGNMENT]: MobileSettingsKeys.MOBILE_TEXT_ALIGNMENT,
  [SettingsKeys.DISPLAY_IMAGE]: MobileSettingsKeys.MOBILE_DISPLAY_IMAGE,
  [SettingsKeys.DISPLAY_TAG_LINE]: MobileSettingsKeys.MOBILE_DISPLAY_TAG_LINE,
  [SettingsKeys.DISPLAY_DIVIDER]: MobileSettingsKeys.MOBILE_DISPLAY_DIVIDER,
  [SettingsKeys.DISPLAY_PRICE]: MobileSettingsKeys.MOBILE_DISPLAY_PRICE,
  [SettingsKeys.DISPLAY_DURATION]: MobileSettingsKeys.MOBILE_DISPLAY_DURATION,
  [SettingsKeys.DISPLAY_START_DATE]:
    MobileSettingsKeys.MOBILE_DISPLAY_START_DATE,
  [SettingsKeys.DISPLAY_DAYS_OFFERED]:
    MobileSettingsKeys.MOBILE_DISPLAY_DAYS_OFFERED,
  [SettingsKeys.DISPLAY_BUTTON]: MobileSettingsKeys.MOBILE_DISPLAY_BUTTON,
};

const mobileSettingsDefaultsToDesktopKeysMap = {
  [MobileSettingsKeys.MOBILE_CARDS_SPACING]: [SettingsKeys.CARDS_SPACING],
  [MobileSettingsKeys.MOBILE_IMAGE_SHAPE_OPTION]: [
    SettingsKeys.IMAGE_SHAPE_OPTION,
  ],
  [MobileSettingsKeys.MOBILE_TEXT_ALIGNMENT]: [SettingsKeys.TEXT_ALIGNMENT],
  [MobileSettingsKeys.MOBILE_DISPLAY_IMAGE]: [SettingsKeys.DISPLAY_IMAGE],
  [MobileSettingsKeys.MOBILE_DISPLAY_TAG_LINE]: [SettingsKeys.DISPLAY_TAG_LINE],
  [MobileSettingsKeys.MOBILE_DISPLAY_DIVIDER]: [SettingsKeys.DISPLAY_DIVIDER],
  [MobileSettingsKeys.MOBILE_DISPLAY_PRICE]: [SettingsKeys.DISPLAY_PRICE],
  [MobileSettingsKeys.MOBILE_DISPLAY_DURATION]: [SettingsKeys.DISPLAY_DURATION],
  [MobileSettingsKeys.MOBILE_DISPLAY_START_DATE]: [
    SettingsKeys.DISPLAY_START_DATE,
  ],
  [MobileSettingsKeys.MOBILE_DISPLAY_DAYS_OFFERED]: [
    SettingsKeys.DISPLAY_DAYS_OFFERED,
  ],
  [MobileSettingsKeys.MOBILE_DISPLAY_BUTTON]: [SettingsKeys.DISPLAY_BUTTON],
};

const mobileSettingsToDesktopKeysMap = {
  ...mobileSettingsDefaultsToDesktopKeysMap,
};

function mergeSettingsKeys(data, keysMap, forceMerge = false) {
  const res = { ...data };
  Object.keys(keysMap).forEach(sourceKey => {
    if (
      typeof res[sourceKey] !== 'undefined' &&
      (forceMerge || typeof res[keysMap[sourceKey]] === 'undefined')
    ) {
      res[keysMap[sourceKey]] = res[sourceKey];
    }
  });
  return res;
}

export const mergeDesktopDefaultsIntoMobile = settingsData =>
  mergeSettingsKeys(settingsData, desktopSettingsDefaultsToMobileMap);
export const mergeMobileDefaultsIntoDesktop = settingsData =>
  mergeSettingsKeys(settingsData, mobileSettingsDefaultsToDesktopKeysMap, true);
export const mergeMobileSettingsIntoDesktop = settingsData =>
  mergeSettingsKeys(settingsData, mobileSettingsToDesktopKeysMap, true);
